import { createSimpleGlobalState } from "src/hooks/simpleGlobalState"
import { xRMApiProductVariant } from "src/types/xRM"

export type ProductVariant = xRMApiProductVariant & {
  isOptional?: boolean;
};

export type ProductVariants ={
  loading: boolean
  variants: ProductVariant[],
}

const { getValue, setValue, mergeValue, useValue } =
  createSimpleGlobalState<ProductVariants>(
    {
      loading: true,
      variants: [],
    },
  )

export {
  getValue as getProductVariants,
  setValue as setProductVariants,
  mergeValue as mergeProductVariants,
  useValue as useProductVariants,
}
