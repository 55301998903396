import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Skeleton } from '@mui/material';

import { useRouter } from 'next/router';

import { FC, useEffect, useMemo, useState } from "react"

import { Button } from "src/components/common/button"
import { ShopCartProductCard } from 'src/components/services/shop/cart/ShopCartProductCard';
import { create } from 'src/helpers/bem';
import { getPage } from 'src/helpers/pages';
import { useLocale } from 'src/states/locale';
import { postRefreshCart, setCart, useCart } from 'src/states/shop/carts';
import { TranslationMessages } from "src/translations"

import { PagesProps } from 'src/types/SharedPageProps';

import { xRMApiTermsOfService } from 'src/types/xRM';

import styles from "./ShopCartOverview.module.scss"
import { BillingOptions, defaultBillingOptions, ShopCartOverviewBillingOptionsCard } from './ShopCartOverviewBillingOptionsCard';
import { ShopCartOverviewSummary } from './ShopCartOverviewSummary';
import { ShopCartOverviewTermsOfServicesCard } from './ShopCartOverviewTermsOfServicesCard';


const bem = create(styles, "ShopCartOverview")

export type ShopCartOverviewProps = {
	messages: TranslationMessages,
	pages: PagesProps
}

export const ShopCartOverview: FC<ShopCartOverviewProps> = ({ messages, pages }) => {
	const locale = useLocale()
	const router = useRouter()
	const cart = useCart({ id: router.query.cartId as string})
	const m = messages.pages.shop.cart.overview
	const [ termsOfServices, setTermsOfServices ] = useState<xRMApiTermsOfService[]>([])
	const [ billingOptions, setBillingOptions ] = useState<BillingOptions>(defaultBillingOptions)
	const [ loading, setLoading ] = useState(false)

	useEffect(() => {
		setLoading(true)
		const refreshCartAsync = async () => setCart(await postRefreshCart(cart))
		refreshCartAsync().finally(() => setLoading(false))
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [locale])

	const webshopOverviewPage = useMemo(() => getPage("Webshop Overview Template", locale, pages),[pages, locale])
	return (
		<div className={bem()}>
			<Button
				startIcon={<ArrowBackIcon />}
				title={m.backToShop}
				variant="text"
				onClick={async () => await router.push({
					pathname: webshopOverviewPage?.uri as string,
					query: {
						cartId: router.query.cartId
					}
				})}
			/>
			<div className={bem('container')}>
				<div className={bem('items')}>
					{loading ? <div className={bem('skeleton')}>
									<Skeleton className={bem('skeleton__first')} height={70} variant="rectangular" width={70}/>
									<Skeleton className={bem('skeleton__second')} height={100} variant="rectangular" width={100} />
									<div className={bem("skeleton-items")}>
										<Skeleton height={40} width={200} />
										<Skeleton className={bem("skeleton-item")} height={40} width={100} />
										<Skeleton className={bem("skeleton-item")} height={40} width={100} />
									</div>
								</div>
						: cart.products && cart.products?.length > 0
							? cart.products.map(product =>
								<ShopCartProductCard
									key={product.productVariant?.id}
									displayDetails={true}
									item={product}
								/>)
							: <div className={bem('noData')}>{m.noData}</div>}
				</div>
				<div className={bem('summary')}>
					<ShopCartOverviewTermsOfServicesCard isCategoryRelated={true} setTermsOfServices={setTermsOfServices} termsOfServices={termsOfServices}/>
					<ShopCartOverviewTermsOfServicesCard isCategoryRelated={false} setTermsOfServices={setTermsOfServices} termsOfServices={termsOfServices}/>
					<ShopCartOverviewBillingOptionsCard billingOptions={billingOptions} setBillingOptions={setBillingOptions} />
					<ShopCartOverviewSummary billingOptions={billingOptions} pages={pages} termsOfServices={termsOfServices} />
				</div>
			</div>
		</div>
	)
}
