import NoPhotographyIcon from "@mui/icons-material/NoPhotography"
import { CardContent, Divider, List, ListItem, ListItemButton } from "@mui/material"
import { useRouter } from "next/router"
import { FC, useMemo } from "react"
import { Card } from "src/components/common/card"
import { LazyImage } from "src/components/common/lazyImage"
import { create } from "src/helpers/bem"
import { getPage } from "src/helpers/pages"
import { formatPrice } from "src/helpers/price"
import { useLocale } from "src/states/locale"
import { addNotification } from "src/states/notifications"
import { useProducts } from "src/states/shop/products"
import { TranslationMessages } from "src/translations"
import { PagesProps } from "src/types/SharedPageProps"
import { xRMApiFilledProductAttribute, xRMApiIncident, xRMApiPurchaseorder, xRMApiSalesorderDetail } from "src/types/xRM"

import styles from "./ShopOrderDetailsItems.module.scss"
import { ShopOrderDetailsItemStatus } from "./ShopOrderDetailsItemStatus"

const bem = create(styles, "ShopOrderDetailsItems")

export type ShopOrderDetailsItemsProps = {
	incidents: xRMApiIncident[]
	order: xRMApiPurchaseorder
	pages: PagesProps
	messages: TranslationMessages
}

export const ShopOrderDetailsItems: FC<ShopOrderDetailsItemsProps> = ({ incidents, order, pages, messages }) => {
	const locale = useLocale()
	const router = useRouter()
	const m = messages?.pages.shop.orders.details
	const { products } = useProducts()

	const renderAttributeValue = (attribute: xRMApiFilledProductAttribute) => {
		switch (attribute.attributeType) {
			case "Boolean":
			case "Option":
				return attribute.calculatedValue
			case "MultipleOptions":
				return attribute.formattedMultipleOptionsValue
			case "PretixShipping":
				return `${attribute.pretixSalutationTextValue} ${attribute.pretixFirstnameTextValue} ${attribute.pretixLastnameTextValue} (${attribute.pretixEmailTextValue})`
			case "Currency":
				return attribute.currencyValue
			case "Number":
				return attribute.numberValue
			default:
				return attribute.textValue
		}
	}

	const webshopProductDetailPage = useMemo(() => getPage("Webshop Product Detail Template", locale, pages),[pages, locale])

	const routerPush = async (productId :string | undefined) => {
		await router.push({
		pathname: webshopProductDetailPage?.uri as string,
		query: {
			id: productId,
		}
	})}

	const itemStatus = (item: xRMApiSalesorderDetail) => {
		switch (item?.status) {
			case 'Active': return m?.status.active
			case 'Cancelled': return m?.status.cancelled
			case 'Invoiced': return m?.status.invoiced
			case 'Approved': return m?.status.approved
			default: return undefined
		}
	}

	const handleItemClicked = async (item: xRMApiSalesorderDetail) => {
		const product = products.find((p) => p.id === item.purchaseorderProduct?.id)
		const parentProduct = products.find((p) => p.id === item.purchaseorderProduct?.variantParentId)
		if(product)
		{
			routerPush(product?.id)
		}
		else if (parentProduct)
		{
			routerPush(parentProduct?.id)
		}
		else
		{
			addNotification({
				autoclose: true,
				variant: "warning",
				content: `${m?.snackbar.noProductFound}`,
			  })
		}
	}

	return (
		<Card>
			<CardContent>
				<List className={bem()}>
					{order.salesorderDetails?.map(item => {
						const attributes = order.filledProductAttributes?.filter(a => a.salesorderDetailId === item.id) ?? []
						return (
							<ListItem
								key={item.id}
								disablePadding
								className={bem('listitem')}
							>
								<ListItemButton
									className={bem('listitem__button')}
									onClick={() => handleItemClicked(item)}
								>
									<div className={bem('listitem__content')}>
										<div className={bem('listitem__content__image')}>
											{item.purchaseorderProduct?.images?.[0]?.src
												? <LazyImage
													alt={item.purchaseorderProduct?.images?.[0].alt ?? item.purchaseorderProduct?.name ?? ""}
													src={item.purchaseorderProduct?.images?.[0].src} />
												: <NoPhotographyIcon color="disabled" />}
										</div>
										<div className={bem('listitem__content__detail')}>
											<div className={bem('listitem__content__detail__name')}>
												<div className={bem('listitem__content__detail__name__container')}>
													{item.purchaseorderProduct?.name}
													<div
													className={bem('listitem__content__detail__status')}
													color="info"
													>
														{ itemStatus(item) }
													</div>
												</div>
												<ShopOrderDetailsItemStatus incidents={incidents} item={item} size={'small'} />
											</div>
											<div className={bem('listitem__content__detail__attributes')}>
												{attributes.map(attribute => renderAttributeValue(attribute) === undefined ||
																			 renderAttributeValue(attribute) === "" ?
													<></>
													:
													<div
														key={attribute.id}
														className={bem('listitem__content__detail__attributes__attribute')}
													>
														<div>{attribute.name}</div>
														<div>{renderAttributeValue(attribute)}</div>
													</div>
												)}
											</div>
											<div className={bem("listitem__content__detail__price")}>
												<div>
													{formatPrice(item.pricePerUnit, locale)} x {item.quantity} {locale === 'de' ? item.unit?.name : item.unit?.englishName}
												</div>
												<div>
													{formatPrice(item.netTotalAmount, locale)}
												</div>
											</div>
										</div>
									</div>
								</ListItemButton>
								<Divider className={bem('divider')} />
							</ListItem>
						)
					})}
				</List>
				<div className={bem('total')}>
					{formatPrice(order.totalAmount, locale)}
				</div>
			</CardContent>
		</Card>
	)
}