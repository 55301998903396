import { ArrowDropDown, Cancel, Feedback, Print } from "@mui/icons-material"
import { LinearProgress, Skeleton } from "@mui/material"
import { useRouter } from "next/router"
import { FC, useEffect, useRef, useState } from "react"
import { useReactToPrint } from "react-to-print"
import { BasicDropdown, DropdownRef } from "src/components/common/dropdown"
import { create } from "src/helpers/bem"

import { fetchCategories } from "src/queriesXRM/categories"
import { fetchWebshopIncidentsByOrder } from "src/queriesXRM/incident"
import { fetchWebshopOrder } from "src/queriesXRM/purchaseorder"
import { Dialogs, mergeDialog, useDialog } from "src/states/dialogs"
import { useLocale } from "src/states/locale"
import { mergeCategories } from "src/states/shop/category"
import { TranslationMessages } from "src/translations"

import { PageProps, PagesProps } from "src/types/SharedPageProps"
import { xRMApiIncident, xRMApiPurchaseorder } from "src/types/xRM"

import styles from "./ShopOrderDetails.module.scss"
import { ShopOrderDetailsAddress } from "./ShopOrderDetailsAddress"
import { ShopOrderDetailsItems } from "./ShopOrderDetailsItems"
import { ShopOrderDetailsSummary } from "./ShopOrderDetailsSummary"

const bem = create(styles, "ShopOrderDetails")

export type ShopOrderDetailsProps = {
	messages: TranslationMessages
    pages: PagesProps
    page: PageProps
}

export const ShopOrderDetails: FC<ShopOrderDetailsProps> = ({ messages, pages, page }) => {
    const router = useRouter()
    const locale = useLocale()
    const orderId = router.query.orderId as string
    const [ loading, setLoading ] = useState(true)
    const [ order, setOrder ] = useState<xRMApiPurchaseorder | null>(null)
    const [ incidents, setIncidents ] = useState<xRMApiIncident[]>([])
    const { shouldRefreshIncidents, incidentLoading } = useDialog('shopOrderDetailsCancelOrderDialog') as Dialogs['shopOrderDetailsCancelOrderDialog']
    const dropdownRef = useRef<DropdownRef>(null)
    const printRef = useRef(null)
    const m = messages.pages.shop.orders.details

    const loadCategoriesAsync = async () => {
        mergeCategories({ loading: true })
        mergeCategories({ loading: false, categories: await fetchCategories() })
    }

    useEffect(() => {
        if (!orderId) return
        setLoading(true)
        const getOrderDetailsAsync = async () => {
            await loadCategoriesAsync()
            setOrder(await fetchWebshopOrder({ id: orderId }))
            setIncidents(await fetchWebshopIncidentsByOrder({ id: orderId }))
        }
        getOrderDetailsAsync().finally(() => setLoading(false))
    }, [orderId, locale])

    useEffect(() => {
        if (!orderId) return
        if (shouldRefreshIncidents) {
            const setIncidentsAsync = async () => {
                setIncidents(await fetchWebshopIncidentsByOrder({ id: orderId }))
            }
            mergeDialog('shopOrderDetailsCancelOrderDialog', { shouldRefreshIncidents: false })
            setIncidentsAsync().finally(() => mergeDialog('shopOrderDetailsCancelOrderDialog', { incidentLoading: false }))
        }
    } , [shouldRefreshIncidents])

    const handlePrint = useReactToPrint({
        content: () => printRef.current,
        documentTitle: m.print.title,
        copyStyles: true,
        pageStyle: `
            @media print {
                @page { size: a4 portrait; } 
                .pagebreak { page-break-before: always; }
            }
        `
    })

    const dropdownButton =
        <span>
            <ArrowDropDown />
            {m.dropdown.label}
        </span>

    let dropdownItems = [
        {
            sortBy: 0,
            key: "print",
            label: m.dropdown.print,
            icon: <Print />,
            onClick: () => handlePrint(),
        },
        {
            sortBy: 1,
            key: "complain",
            label: m.dropdown.complain,
            icon: <Feedback />,
            onClick: () => mergeDialog('shopOrderDetailsComplainDialog', {
                order,
                open: true,
            }),
        },
        {
            sortBy: 2,
            key: "cancel",
            label: m.dropdown.cancel,
            icon: <Cancel />,
            onClick: () => mergeDialog('shopOrderDetailsCancelOrderDialog', {
                order,
                incidents,
                open: true,
            }),
        },
    ]

	if (loading) return <LinearProgress color="primary" />

    if (order === null) return <p className={bem('description')}>{m.noData}</p>

    return (
        <div className={bem()}>
            <div className={bem("header")}>
				<div>
                    <h1>{page?.data?.title}</h1>
                    <span className={bem("header__status", order.phase)}>
                        { m.summary.status.items[order.phase!] }
                    </span>
                </div>
				<BasicDropdown
                    ref={dropdownRef}
                    button={dropdownButton}
                    items={dropdownItems}
                />
			</div>
            <div ref={printRef}>
				<div className={bem('summary')}>
                    <ShopOrderDetailsSummary order={order} />
                </div>
                <div className={bem('address')}>
                    <ShopOrderDetailsAddress order={order} />
                </div>
				<div className={bem('details')}>
                    {incidentLoading ? <Skeleton height={40} width={"100%"} /> :
                    <ShopOrderDetailsItems incidents={incidents} messages={messages} order={order} pages={pages}/>}
                </div>
            </div>
        </div>
    )
}