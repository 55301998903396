import { ImmutableObject } from "@hookstate/core"
import StarIcon from "@mui/icons-material/Star"
import { Alert } from "@mui/material"
import React, { FC } from "react"

import { create } from "src/helpers/bem"
import { formatPrice } from "src/helpers/price"
import { useLocale } from "src/states/locale"
import { ProductVariant } from "src/states/shop/productVariants"

import { TranslationMessages } from "src/translations"

import styles from "./ShopDetailOptionalProducts.module.scss"

const bem = create(styles, "ShopDetailOptionalProducts")

export type ShopDetailOptionalProductsProps = {
  variant: ImmutableObject<ProductVariant>
  setVariant: React.Dispatch<
    React.SetStateAction<ImmutableObject<ProductVariant>>
  >
  messages: TranslationMessages
}

export const ShopDetailOptionalProducts: FC<
  ShopDetailOptionalProductsProps
> = ({ variant, setVariant, messages }) => {
  const locale = useLocale()
  const m = messages.pages.shop.detail.tab

  return (
    <div className={bem()}>
      <Alert className={bem("info")} severity="info">
        <p className={bem("info__text")}>{m?.info}</p>
      </Alert>
      {variant.optionalProducts && variant.optionalProducts?.length > 0 ? (
        <>
          <div className={bem("container")}>
            <div className={bem("products")}>
              {variant.optionalProducts.map((optionalProduct, i) => (
                <button
                  key={`optional-product-${i}`}
                  className={bem("product")}
                  onClick={() => {
                    const updatedOptionalProduct = {
                      ...optionalProduct,
                      isOptional: true,
                    }
                    setVariant(updatedOptionalProduct)
                  }}
                >
                  {optionalProduct?.favorite ? (
                    <StarIcon className={bem("product__favorite")} />
                  ) : null}
                  <div className={bem("product__title")}>
                    {optionalProduct?.name}
                  </div>
                  <div className={bem("product__price")}>
                    {formatPrice(optionalProduct.price, locale)}{" "}
                    {optionalProduct?.unit && (
                      <i className={bem("product__price__unit")}>
                        / {optionalProduct.unit}
                      </i>
                    )}
                  </div>
                </button>
              ))}
            </div>
          </div>
        </>
      ) : null}
    </div>
  )
}
