import { ImmutableObject } from "@hookstate/core"
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined"
import { Button } from "@mui/material"
import React, { FC, useMemo } from "react"

import { Link } from "src/components/common/link"

import { create } from "src/helpers/bem"

import { useLocale } from "src/states/locale"

import { ProductVariant } from "src/states/shop/productVariants"
import { TranslationMessages } from "src/translations"

import { PagesProps } from "src/types/SharedPageProps"

import styles from "./ShopDetailHeaderNavigation.module.scss"

const bem = create(styles, "ShopDetailHeaderNavigation")


export type ShopDetailHeaderNavigationProps = {
  pages: PagesProps
  messages: TranslationMessages,
  rollback: () => void,
  variant: ImmutableObject<ProductVariant>
}

export const ShopDetailHeaderNavigation: FC<
  ShopDetailHeaderNavigationProps
> = ({ pages, messages, variant, rollback }) => {
  const m = messages.pages.shop.detail.header.navigation
  const locale = useLocale()
  const redirectShopOverview = useMemo(
    () =>
      pages?.data?.nodes?.filter(
        (page) =>
          page?.language?.slug === locale &&
          page?.template?.templateName === "Webshop Overview Template",
      )?.[0],
    [pages, locale],
  )
  return (
    <div className={bem()}>
      {variant.isOptional === true ? <Button onClick={rollback}>
      <ArrowCircleLeftOutlinedIcon />
      <span className={bem("link__label")}>{m.backToProduct}</span>
      </Button>
      :
      <Link
      href={redirectShopOverview?.uri as string}
      classNames={{
        label: bem("link"),
      }}
    >
      <ArrowCircleLeftOutlinedIcon />
      <span className={bem("link__label")}>{m.backToOverview}</span>
    </Link>}
    </div>
  )
}
