import { ImmutableObject } from "@hookstate/core"
import React, { FC, useEffect, useState } from "react"

import { Tabs, TabItem } from "src/components/common/tabs"

import { create } from "src/helpers/bem"

import { Product } from "src/states/shop/products"

import { ProductVariant } from "src/states/shop/productVariants"

import { TranslationMessages } from "src/translations"

import styles from "./ShopDetailContent.module.scss"
import { ShopDetailOptionalProducts } from "./ShopDetailOptionalProducts"

const bem = create(styles, "ShopDetailContent")

export type ShopDetailContentProps = {
  product: ImmutableObject<Product>
  variant: ImmutableObject<ProductVariant>
  setVariant: React.Dispatch<
    React.SetStateAction<ImmutableObject<ProductVariant>>
  >
  messages: TranslationMessages
}

export const ShopDetailContent: FC<ShopDetailContentProps> = ({ product, variant, setVariant, messages }) => {
  const m = messages.pages.shop.detail.tab
  const [contents, setContents] = useState<TabItem[]>([]);

useEffect(() => {
  let newContents: TabItem[] = (variant.contents || [])
    .map(c => {
      const matchingContent = product.contents?.find(cc => cc.label === c.label);
      const node = variant.isOptional ? c.node : c.node || matchingContent?.node;
      return node ? { ...c, node } as TabItem : undefined;
    })
    .filter((c): c is TabItem => !!c);

  if (variant.optionalProducts && variant.optionalProducts.length > 0) {
    newContents.unshift({
      label: m.optionalProductLabel,
      node: <ShopDetailOptionalProducts messages={messages} setVariant={setVariant} variant={variant} />
    });
  }

  setContents(newContents);
}, [messages, variant]);

  return (
    <div className={bem()}>
      <Tabs
        className={bem("tabs")}
        id="product-description-tabs"
        items={contents as TabItem[]}
      />
    </div>
  )
}
