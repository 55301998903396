import { CardActions, CardContent, List, ListItem, ListItemButton, ListItemText, ListSubheader, Skeleton } from "@mui/material"
import router from "next/router"
import { Dispatch, FC, SetStateAction, useEffect, useMemo, useState } from "react"

import { Button } from "src/components/common/button"
import { Card } from "src/components/common/card"
import { Checkbox } from "src/components/common/checkbox"
import { Link } from "src/components/common/link"
import { create } from "src/helpers/bem"

import { useTranslation } from "src/hooks/translation"

import { fetchCategories } from "src/queriesXRM/categories"
import { fetchToS } from "src/queriesXRM/tos"
import { useLocale } from "src/states/locale"
import { useCart } from "src/states/shop/carts"
import { mergeCategories, useCategories } from "src/states/shop/category"
import { xRMApiTermsOfService } from "src/types/xRM"

import styles from "./ShopCartOverviewTermsOfServicesCard.module.scss"

const bem = create(styles, "ShopCartOverviewTermsOfServicesCard")

export type ShopCartOverviewTermsOfServicesCardProps = {
	termsOfServices: xRMApiTermsOfService[]
	setTermsOfServices: Dispatch<SetStateAction<xRMApiTermsOfService[]>>
	isCategoryRelated: boolean
}

export const ShopCartOverviewTermsOfServicesCard: FC<ShopCartOverviewTermsOfServicesCardProps> = ({ termsOfServices, setTermsOfServices, isCategoryRelated }) => {
	const translation = useTranslation()
	const locale = useLocale()
	const { categories } = useCategories()
	const [ loading, setLoading ] = useState(false)
	const cart = useCart({ id: router.query.cartId as string})
	const m = translation.messages.pages.shop.cart.checkout.tosCard
	const cartProductsCount = useMemo(() => cart.products.length, [cart.products.length])
	const cartProductCategories = Array.from(new Set(cart.products.flatMap(p => [p.productVariant?.categoryId, p.productVariant?.parentCategoryId])));

	const loadCategoriesAsync = async () => {
        mergeCategories({ loading: true })
        mergeCategories({ loading: false, categories: await fetchCategories() })
    }

	const generalTermsAndConditions = termsOfServices.filter((tos) => {
		const isGeneralTerms = tos.termsOfService?.urlType === "GeneralTermsAndConditions";
		const categoryId = tos.termsOfService?.categoryId;

		if (isCategoryRelated) {
			return isGeneralTerms && cartProductCategories.includes(categoryId);
		}
		return isGeneralTerms && !categoryId;
	});

	const privacyPolicies = termsOfServices.filter((tos) => {
		const isPrivacyPolicy = tos.termsOfService?.urlType === "PrivacyPolicy";
		const categoryId = tos.termsOfService?.categoryId;

		if (isCategoryRelated) {
			return isPrivacyPolicy && cartProductCategories.includes(categoryId);
		}
		return isPrivacyPolicy && !categoryId;
	});

	const renderCategoryName = (categoryId: any) => {
		const parentCategory = categories.find(c => c.id === categoryId)
		const childCategory = categories.map(c => c.children).flat().find(c => c && c.id === categoryId)
		return parentCategory?.name ?? childCategory?.name ?? ""
	}

	useEffect(() => {
		setLoading(true)
		loadCategoriesAsync()
		const fetchToSAsync = async () => setTermsOfServices((await fetchToS()).filter(t => t.termsOfService?.categoryId == null || cartProductCategories.includes(t.termsOfService?.categoryId)))
		fetchToSAsync().finally(() => setLoading(false))
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [locale])

	useEffect(() => {
		setTermsOfServices(termsOfServices.filter(t => t.termsOfService?.categoryId == null || cartProductCategories.includes(t.termsOfService?.categoryId)))
	}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	, [cartProductsCount])

	const renderTermsOfServicesListLoading = () =>
		<List subheader={<Skeleton height={40} width={100} />}>
			<ListItem sx={{ display: 'flex', gap: '15px'}}>
				<Skeleton height={40} width={"90%"} />
				<Skeleton height={40} width={"5%"} />
			</ListItem>
		</List>

	const renderTermsOfServicesList = (name: string, toS: xRMApiTermsOfService[]) =>
		<List
			className={bem('toslist')}
			subheader={toS.length > 0 ? <ListSubheader>{name}</ListSubheader> : ""}
		>
			{toS.map(t =>
				<ListItem
					key={t.termsOfService?.id}
					disablePadding
					secondaryAction={
						t.termsOfService?.urlType === "GeneralTermsAndConditions" ?
						<Checkbox
							disableRipple
							checked={termsOfServices.find(tt => tt.termsOfService?.id === t.termsOfService?.id)?.value}
							edge="end"
							tabIndex={-1}
							onChange={(_, checked) => setTermsOfServices(
								termsOfServices.map(tt => tt.termsOfService?.id === t.termsOfService?.id
									? { ...tt, value: checked }
									: tt))
							}
						/> : null
					}
				>
					{isCategoryRelated ? <div className={bem('category')}>{m.categoryLabel} "{renderCategoryName(t.termsOfService?.categoryId)}":</div>
					: null}
					<ListItemButton
						dense
						href={`${t.termsOfService!.url!.startsWith('http') ? "" : "http://"}${t.termsOfService!.url!}`}
						LinkComponent={Link}
						target="_blank"
					>
						<ListItemText className={bem('toslink')} primary={t.termsOfService?.name} />
					</ListItemButton>
				</ListItem>)}
		</List>

	return (generalTermsAndConditions.length > 0 || privacyPolicies.length > 0 ?
		<Card>
			<CardContent className={bem('contents')}>
				<div className={bem()}>
					{ loading ? renderTermsOfServicesListLoading() : renderTermsOfServicesList(isCategoryRelated ? m.categoryPrivacyPolicies : m.privacyPolicies, privacyPolicies) }
					{ loading ? renderTermsOfServicesListLoading() : renderTermsOfServicesList(isCategoryRelated ? m.categoryGeneralTermsAndConditions : m.generalTermsAndConditions, generalTermsAndConditions) }
				</div>
			</CardContent>
			<CardActions className={bem('actions')}>
				<div>
					{loading
						? <Skeleton height={40} width={100} />
						: <Button
							title={m.acceptAll}
							variant="text"
							onClick={() => setTermsOfServices(termsOfServices.map(t =>
								t.termsOfService?.urlType === "GeneralTermsAndConditions" && (isCategoryRelated ? t.termsOfService?.categoryId != null : t.termsOfService?.categoryId == null)
								  ? { ...t, value: true }
								  : t))}
						/>}
				</div>
			</CardActions>
		</Card> : <></>
	)
}